<template>
  <div
    :class="{ 'border-danger bg-danger bg-accent-4': !isExistCountry }"
    class="border rounded p-2 bg-grey"
  >
    <div class="d-flex justify-content-between align-items-center mb-2">
      <h4 class="mb-1">
        {{ resolveIsoCountry(item.country_iso) }}

        <b-link
          v-if="!isExistCountry"
          class="ml-2"
          @click="!disabled ? isAddNewCountrySidebarActive = true : null"
        >
          <small class="font-weight-bold text-danger">El país no existe</small>
        </b-link>
      </h4>

      <div>
        <b-button
          v-if="!isExistCountry"
          :disabled="disabled"
          variant="primary"
          size="sm"
          class="mt-0 ml-2"
          @click="isAddNewCountrySidebarActive = true"
        >
          <span>Crear país</span>
        </b-button>

        <b-button
          v-ripple.400="'rgba(234, 84, 85, 0.15)'"
          :disabled="disabled"
          variant="outline-danger"
          size="sm"
          class="mt-0 ml-2"
          @click="onRemoveItem"
        >
          <feather-icon
            icon="XIcon"
            class="mr-25"
          />
          <span>Eliminar</span>
        </b-button>
      </div>
    </div>

    <div style="display: grid; grid-template-columns: repeat(3, minmax(30%, 1fr)); grid-gap: 20px;">
      <banner-card-image
        v-for="(file, i) in item.files"
        ref="refBannerCardImage"
        :key="file.name + i"
        :file="file"
        :disabled="disabled"
        @change="item.files[i] = { ...$event }"
        @removeImage="onRemoveImage(file)"
        @createLanguage="isAddNewUserSidebarActive = true"
      />
    </div>

    <langs-add-new
      :is-add-new-user-sidebar-active.sync="isAddNewUserSidebarActive"
      @refetchData="refetchLangs"
    />

    <country-add-new
      :is-add-new-user-sidebar-active.sync="isAddNewCountrySidebarActive"
      @refetchData="refetchDataCountries"
    />
  </div>
</template>

<script>
import { ref, computed } from '@vue/composition-api'
import Ripple from 'vue-ripple-directive'
import {
  BButton,
  VBToggle,
  // BMedia,
  // BCardText,
  // BMediaAside,
  // BMediaBody,
  // BImg,
  // BFormFile,
  BLink,
  // BFormCheckbox,
} from 'bootstrap-vue'
import { useInputImageRenderer, useFileToBase64 } from '@core/comp-functions/forms/form-utils'
import { useRouter } from '@/@core/utils/utils'
import useBanners from './useBanners'
import useCountries from '../countries/useCountries'
import useLangs from '../langs/useLangs'
import LangsAddNew from '../langs/LangsAddNew.vue'
import CountryAddNew from '../countries/CountryAddNew.vue'
import BannerCardImage from './BannerCardImage.vue'

export default {
  components: {
    BButton,
    // BMedia,
    // BCardText,
    // BMediaAside,
    // BMediaBody,
    // BImg,
    // BFormFile,
    BLink,
    // BFormCheckbox,
    LangsAddNew,
    CountryAddNew,
    BannerCardImage,
  },
  directives: {
    Ripple,
    'b-toggle': VBToggle,
  },
  model: {
    prop: 'item',
    event: 'removeItem',
  },
  props: {
    item: {
      type: Object,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  setup({ item }, { emit }) {
    const { route } = useRouter()

    const refBannerCardImage = ref(null)
    const refInputEl = ref(null)
    const refPreviewEl = ref(null)
    const isAddNewUserSidebarActive = ref(false)
    const isAddNewCountrySidebarActive = ref(false)

    const {
      countries,
      fetchCountriesSelector,
      resolveIsoCountry,
      resolveResolution,
    } = useCountries()
    const {
      langs,
      fetchLangsSelector,
      resolveIsoLang,
    } = useLangs()
    const { imageDelete } = useBanners()

    const isExistCountry = computed(() => resolveIsoCountry(item.country_iso) !== item.country_iso)

    const { inputImageRenderer } = useInputImageRenderer(refInputEl, base64 => { refPreviewEl.value.src = base64 })

    const onChangeFile = async (file, el) => {
      const base64 = await useFileToBase64(file)

      const index = item.files.findIndex(i => i.name === el.name)

      if (index !== -1) {
        // eslint-disable-next-line no-param-reassign
        el.file = base64
      }
    }

    async function onRemoveItem() {
      console.log({ item })
      if (route.value.params.id) {
        const result = await this.$swal({
          title: '¿Estas seguro?',
          text: '¡No podrás revertir esta acción!',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: '¡Sí, bórralo!',
          cancelButtonText: 'Cancelar',
          customClass: {
            confirmButton: 'btn btn-primary',
            cancelButton: 'btn btn-outline-danger ml-1',
          },
          buttonsStyling: false,
        })

        if (result.value) {
          await imageDelete(route.value.params.id, {
            params: {
              images: item.files.map(file => ({
                name: file.name,
                image: file.url.split('/').pop(),
              })),
            },
          })
          emit('removeItem')

          this.$swal({
            icon: 'success',
            title: '¡Borrado!',
            text: 'Las imagenes han sido borradas',
            customClass: {
              confirmButton: 'btn btn-success',
            },
          })
        }
      } else {
        emit('removeItem')
      }
    }

    async function onRemoveImage(file) {
      if (file.url) {
        const result = await this.$swal({
          title: '¿Estas seguro?',
          text: '¡No podrás revertir esta acción!',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: '¡Sí, bórralo!',
          cancelButtonText: 'Cancelar',
          customClass: {
            confirmButton: 'btn btn-primary',
            cancelButton: 'btn btn-outline-danger ml-1',
          },
          buttonsStyling: false,
        })

        if (result.value) {
          await imageDelete(route.value.params.id, {
            params: {
              images: [{
                name: file.name,
                image: file.url.split('/').pop(),
              }],
            },
          })
          emit('removeImage', file.name)

          this.$swal({
            icon: 'success',
            title: '¡Borrado!',
            text: 'La imagen ha sido borrada',
            customClass: {
              confirmButton: 'btn btn-success',
            },
          })
        }
      } else {
        emit('removeImage', file.name)
      }
    }

    function getFileName(txt) {
      if (txt.length < 20) return txt

      const split = txt.split('_')
      const name = `${split.shift().slice(0, 9)}...`

      return `${name}${split.join('_')}`
    }

    function refetchLangs() {
      fetchLangsSelector().then(list => {
        langs.value = list
      })
    }

    function refetchDataCountries() {
      fetchCountriesSelector().then(list => {
        countries.value = list
      })
    }

    function onChangeImage(e, el) {
      const index = item.files.findIndex(i => i.name === el.name)

      if (index !== -1) {
        // eslint-disable-next-line no-param-reassign
        el = { ...e }
        // eslint-disable-next-line no-param-reassign
        item.files[index] = { ...e }
      }
    }

    return {
      resolveIsoCountry,
      resolveResolution,
      resolveIsoLang,

      onChangeFile,
      onRemoveImage,
      onRemoveItem,
      onChangeImage,

      getFileName,
      inputImageRenderer,

      isAddNewUserSidebarActive,
      isAddNewCountrySidebarActive,
      isExistCountry,
      refetchLangs,
      refetchDataCountries,
      refBannerCardImage,
    }
  },
}
</script>
