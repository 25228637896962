<template>
  <b-media
    no-body
    vertical-align="center"
    class="flex-column p-1 align-items-center rounded-lg position-relative"
  >
    <b-button
      v-ripple.400="'rgba(234, 84, 85, 0.15)'"
      :disabled="disabled"
      variant="outline-danger"
      size="sm"
      class="position-absolute btn-icon"
      style="top: 6px; right: 6px"
      @click="!disabled ? $emit('removeImage', file) : null"
    >
      <feather-icon icon="XIcon" />
    </b-button>
    <b-media-aside class="mx-auto position-relative">
      <div
        v-if="loading"
        class="align-items-center d-flex flex-column h-100 justify-content-center position-absolute rounded text-white top-left w-100"
        style="background-color: #161d30ba;"
      >
        <b-spinner />
        Subiendo...
      </div>

      <div
        v-if="isError"
        class="align-items-center d-flex flex-column h-100 justify-content-center position-absolute rounded text-white top-left w-100"
        style="background-color: #161d30ba;"
      >
        <b-button
          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
          variant="primary"
          class="bg-white"
          @click="uploadFile(file.file)"
        >
          Volver a subir
        </b-button>
      </div>

      <b-img
        ref="refPreviewEl"
        :src="file.file || file.url"
        height="110"
        width="170"
        class="rounded mb-1 mb-md-0 cursor-pointer"
        @click="zoomImage(file.file || file.url)"
      />
    </b-media-aside>
    <b-media-body class="d-flex flex-column align-items-center">
      <b-card-text class="my-50 text-center">
        <b-link id="blog-image-text">
          {{ resolveResolution(file.type) }} {{ resolveIsoLang(file.lang) }}
        </b-link>
      </b-card-text>
      <b-card-text
        v-if="resolveIsoLang(file.lang) === file.lang"
        class="mt-50 mb-2 text-center"
      >
        <b-link
          class="d-block"
          @click="!disabled ? $emit('createLanguage') : null"
        >
          <span class="text-danger">Crear idioma</span>
        </b-link>
      </b-card-text>
      <small class="my-2 mt-auto h6">{{ getFileName(file.name) }}</small>
      <div class="d-inline-block">
        <b-form-file
          ref="refInputEl"
          :disabled="disabled || loading"
          accept=".jpg, .png, .webp"
          placeholder="Elija el archivo"
          @input="onChangeFile($event, file)"
        />
      </div>
      <div
        class="d-flex justify-content-between align-items-center w-100 my-1"
      >
        <label
          :for="file.name + '-check-auth'"
          class="m-0 w-100"
        >Requiere autenticación</label>
        <b-form-checkbox
          :id="file.name + '-check-auth'"
          v-model="file.requiredAuth"
          :disabled="disabled"
          switch
        />
      </div>
    </b-media-body>
  </b-media>
</template>

<script>
import { ref, onMounted } from '@vue/composition-api'
import Ripple from 'vue-ripple-directive'
import {
  BButton, VBToggle, BMedia, BCardText, BMediaAside, BMediaBody, BImg, BFormFile, BLink, BFormCheckbox, BSpinner,
} from 'bootstrap-vue'
import { useFileToBase64 } from '@core/comp-functions/forms/form-utils'
import useBanners from './useBanners'
import useCountries from '../countries/useCountries'
import useLangs from '../langs/useLangs'

export default {
  components: {
    BButton,
    BMedia,
    BCardText,
    BMediaAside,
    BMediaBody,
    BImg,
    BFormFile,
    BLink,
    BFormCheckbox,
    BSpinner,
  },
  directives: {
    Ripple,
    'b-toggle': VBToggle,
  },
  model: {
    prop: 'item',
    event: ['change', 'createLanguage', 'removeImage'],
  },
  props: {
    file: {
      type: Object,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  setup({ file }, { emit }) {
    const refInputEl = ref(null)
    const refPreviewEl = ref(null)

    const loading = ref(false)
    const isError = ref(false)

    const {
      bannerFileUpload,
    } = useBanners()
    const {
      resolveIsoCountry,
      resolveResolution,
    } = useCountries()
    const {
      resolveIsoLang,
    } = useLangs()

    async function uploadFile(base64) {
      loading.value = true
      isError.value = false

      try {
        const url = await bannerFileUpload({ ...file, file: base64 })

        const value = file

        value.file = url ? '' : base64
        value.url = url ?? ''

        emit('change', value)
      } catch (e) {
        console.log(`Error uploading file "${file.name}"`)
        isError.value = true
      } finally {
        loading.value = false
      }
    }

    const onChangeFile = async (event, el) => {
      const base64 = await useFileToBase64(event)
      console.log({ base64, el })

      await uploadFile(base64)
    }

    onMounted(async () => {
      console.log('onMounted')
      if (file.file && file.file.startsWith('data:image')) {
        await uploadFile(file.file)
      }
    })

    function getFileName(txt) {
      if (txt.length < 20) return txt

      const split = txt.split('_')
      const name = `${split.shift().slice(0, 9)}...`

      return `${name}${split.join('_')}`
    }

    function zoomImage(url) {
      this.$swal({
        html: `<img class="w-100" src="${url}" alt="${url}">`,
        showConfirmButton: false,
        showCloseButton: false,
        customClass: {
          popup: 'swal-image-zoom',
        },
      })
    }

    return {
      refInputEl,
      refPreviewEl,

      loading,
      isError,

      uploadFile,
      onChangeFile,
      getFileName,
      resolveIsoCountry,
      resolveResolution,
      resolveIsoLang,
      zoomImage,
    }
  },
}
</script>

<style lang="scss" scoped>
@import "~@core/scss/base/pages/app-invoice.scss";
@import '~@core/scss/base/components/variables-dark';

.media {
  background: white;
  border: 1px solid lightgray;
}

.dark-layout {
  .media {
    background-color: $theme-dark-body-bg;
    border-color: $theme-dark-border-color;

    &, p, small {
      color: $theme-dark-headings-color;
    }

    .row .border {
      background-color: $theme-dark-card-bg;
    }

  }
}
</style>
